import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Routes from "../../routes"
import { useImpression } from "../../analytics/utils"

const AboutPage = ({ location }) => {
  useImpression("About")
  return (
    <Layout location={location}>
      <Seo
        title="About Religious Religion"
        description="Freethinkers who want to question the standard, and get to the bottom of religion."
      />
      <h1>About us</h1>
      <p>
        We see ourselves as freethinkers who want to explore and learn about why
        religion is so prevalent, and how it can and cannot fit into our
        futures. After careful consideration, it can start to seem painfully
        clear that religion is not something that belongs in our future. In
        today's world it is causing more harm than good. It divides us into
        groups and promotes believing in things without evidence, something you
        effectively never do outside the context religion.
      </p>
      <p>
        We ask questions about religion. We debate about every detail from the
        literal interpretations written in religious sacred texts, to the
        philosophical questions they raise and everything in between. With a
        majority of the world belonging to one religion or another, it's clearly
        something that dramatically impacts our culture, our way of living,
        thinking, and growing as one planet. So it's important to talk about it.
      </p>
      <p>
        We've created this as a place to learn more about religious ideas, some
        good, and some bad. We hope to encourage the freethinking of religious
        people in that they'll reconsider their beliefs and accept that we don't
        know all of the answers. It seems important that the world be in
        alignment about the nature of our reality, as it has the power to shape
        our future.
      </p>
      <p>
        Hopefully you can find value in the material we have here, and use it to
        explore new ideas, concepts, and ways of living. And be sure to leave
        comments and engage to fully discuss and debate. These topics are dense,
        and they can take shape over a long time. So keep a level head, an open
        mind, and dive in.
      </p>
      <Link to={Routes.HOME}>Go home</Link>
    </Layout>
  )
}

export default AboutPage
